$(document).ready(function() {

    $('.js-h-lang').select2({
        minimumResultsForSearch: -1
    });

    //$('.js-select-program').select2({
    //    placeholder: "Программа",
    //    minimumResultsForSearch: -1
    //});
    //$('.js-select-day').select2({
    //    placeholder: "День тренировки",
    //    minimumResultsForSearch: -1
    //});
    //$('.js-select-age').select2({
    //    placeholder: "Возраст",
    //    minimumResultsForSearch: -1
    //});
    //$('.js-select-language').select2({
    //    placeholder: "Язык",
    //    minimumResultsForSearch: -1
    //});

    $('.js-select-training-count').select2({
        placeholder: "Количество тренировок",
        minimumResultsForSearch: -1
    });
    //$('.js-select-country').select2({
    //    placeholder: "Страна",
    //    minimumResultsForSearch: -1
    //});
    //$('.js-select-time-zone').select2({
    //    placeholder: "Часовой пояс ",
    //    minimumResultsForSearch: -1
    //});
    //$('.js-select-city').select2({
    //    placeholder: "Город",
    //    minimumResultsForSearch: -1
    //});
    //$('.js-select-training-name').select2({
    //    placeholder: "Название тренировки",
    //    minimumResultsForSearch: -1
    //});

    $('.filters-btn').click(function(){
        $(".filters-form").slideToggle("slow");
    });

    $('.js-calendar-slider').slick({
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: 'linear'
    });

    $('#regForm').validate();
});

$(document).on('click','.myBtn',function(){
    var myTargetModal = '#' + $(this).data('bid');
    console.log(myTargetModal);
	$('#myModal').hide();
	$('.modal-content').hide();
	$('#myModal').fadeIn();
	$(myTargetModal).fadeIn();
});
$("body" ).on( "click",".close", function() {
    $('#myModal').hide();
  $('.modal-content').hide();
});
$(document).click(function(e) {
  if ($(e.target).is('#myModal')) {
      $('#myModal').hide();
      $('.modal-content').hide();
  }
});

if ($('#read').hasClass('more')) {
    var txt = document.getElementById('txt').textContent,
        txtOnly = document.getElementById('txt'),
        btn = document.getElementById('read'),
        subTxt = txt.substring(0,1000);

    txtOnly.style.display ="none";

    function txtD(){

        if(txt.length > 100) {
        txtOnly.innerHTML = subTxt;
        txtOnly.style.display="block";
        console.log(txt.length);
        }  
        else {
        txtOnly.innerHTML = txt;
        }
    };
    txtD();
    btn.onclick = function () {
        'use strict';
        if(btn.classList.contains('more')) {
        btn.classList.remove('more');
        btn.classList.add('less');
        btn.textContent = "Свернуть текст";
        txtOnly.innerHTML = txt;
        }
        else {
        btn.classList.remove('less');
        btn.classList.add('more');
        btn.textContent = "Читать больше";
        txtOnly.innerHTML = subTxt;
        }
    };
}

if ($('#regForm').hasClass('booking-form')) {
    var currentTab = 0; // Current tab is set to be the first tab (0)
    showTab(currentTab); // Display the current tab

    function showTab(n) {
    // This function will display the specified tab of the form...
    var x = document.getElementsByClassName("tab");
    x[n].style.display = "block";
    //... and fix the Previous/Next buttons:
    //if (n == 0) {
    //    document.getElementById("prevBtn").style.display = "none";
    //} else {
    //    document.getElementById("prevBtn").style.display = "inline";
    //}
    if (n == (x.length - 1)) {
        document.getElementById("nextBtn").innerHTML = "Оплатить";
    } else {
        document.getElementById("nextBtn").innerHTML = "Выбрать дату";
    }
    //... and run a function that will display the correct step indicator:
    fixStepIndicator(n)
    }

    function nextPrev(n) {
    // This function will figure out which tab to display
    var x = document.getElementsByClassName("tab");
    // Exit the function if any field in the current tab is invalid:
    if (n == 1 && !validateForm()) return false;
    // Hide the current tab:
    x[currentTab].style.display = "none";
    // Increase or decrease the current tab by 1:
    currentTab = currentTab + n;
    // if you have reached the end of the form...
    if (currentTab >= x.length) {
        // ... the form gets submitted:
        document.getElementById("regForm").submit();
        return false;
    }
    // Otherwise, display the correct tab:
    showTab(currentTab);
    }

    function validateForm() {
    // This function deals with validation of the form fields
    var x, y, i, valid = true;
    x = document.getElementsByClassName("tab");
    y = x[currentTab].getElementsByTagName("input");
    // A loop that checks every input field in the current tab:
    for (i = 0; i < y.length; i++) {
        // If a field is empty...
        if (y[i].value == "") {
        // add an "invalid" class to the field:
        y[i].className += " invalid";
        // and set the current valid status to false
        valid = false;
        }
    }
    // If the valid status is true, mark the step as finished and valid:
    if (valid) {
        document.getElementsByClassName("step")[currentTab].className += " finish";
    }
    return valid; // return the valid status
    }

    function fixStepIndicator(n) {
    // This function removes the "active" class of all steps...
    var i, x = document.getElementsByClassName("step");
    for (i = 0; i < x.length; i++) {
        x[i].className = x[i].className.replace(" active", "");
    }
    //... and adds the "active" class on the current step:
    x[n].className += " active";
    }
}